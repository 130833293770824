<template>
  <div class="flex w-full h-full bg-qgo-primary-bg">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <transition name="slide" mode="out-in">
      <div
        v-if="popupmodal"
        class="
          flex
          items-center
          justify-center
          w-full
          min-h-screen
          px-5
          py-10
          bg-gray-500 bg-opacity-70
        "
      >
        <form
          class="
            flex flex-col
            h-auto
            pb-5
            overflow-y-auto
            bg-gray-200
            rounded-xl
          "
          autocomplete="off"
          @submit.prevent="createAccount"
          novalidate
        >
          <div class="flex flex-row py-5 bg-white border-b rounded-xl">
            <div class="flex items-center justify-start w-1/2 ml-5">
              <span
                id="cancelSignup"
                class="text-sm cursor-pointer text-qgo-primary-bg"
                @click="$router.push({ name: 'VendorSignin' })"
                >Cancel</span
              >
            </div>
            <div class="flex items-center justify-center w-1/2 ml-5">
              <span class="text-sm font-bold">Create a Vendor Account</span>
            </div>
            <div class="flex items-center justify-end w-1/2 mr-5">
              <button
                type="submit"
                class="text-sm cursor-pointer text-qgo-primary-bg"
              >
                Create Account
              </button>
            </div>
          </div>
          <div class="flex items-center content-between justify-center">
            <img
              src="@/assets/img/HorizBlackGrn.png"
              alt=""
              class="self-center h-20 w-52"
            />
          </div>
          <div
            v-if="error"
            class="flex ml-8"
            style="color: red; font-weight: bold"
          >
            {{ error }}
          </div>
          <div class="flex ml-8">
            <span class="text-gray-500 mb-2">ACCOUNT INFO</span>
          </div>

          <div
            class="
              flex flex-col
              items-center
              justify-start
              px-3
              py-2
              mx-4
              bg-white
              rounded-lg
            "
          >
            <div class="flex flex-col w-full">
              <span class="px-2 text-left"
                >Email <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <input
                type="email"
                name="accountEmail"
                @input="emailVerification"
                :class="
                  validStatus.email
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline'
                "
                placeholder="name@example.com"
                required
                @blur="onBlur('email')"
                v-model="input.email"
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.email"
              >
                {{ errors.email }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <span class="px-2 text-left"
                >Password <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <input
                type="password"
                name="accountPassword"
                @input="passwordVerification"
                :class="
                  validStatus.password
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline'
                "
                placeholder="Enter password"
                required
                minlength="6"
                @blur="onBlur('password')"
                v-model="input.password"
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.password"
              >
                {{ errors.password }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <span class="px-2 text-left"
                >Confirm Password <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <input
                type="password"
                name="accountConfirmPassword"
                @input="cpasswordVerification"
                :class="
                  validStatus.confirm_password
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none'
                "
                placeholder="Confirm password"
                required
                @blur="onBlur('confirm_password')"
                v-model="input.confirm_password"
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.confirm_password"
              >
                {{ errors.confirm_password }}
              </p>
            </div>
          </div>
          <div
            class="
              flex flex-col
              items-center
              justify-start
              px-3
              mx-4
              mt-6
              bg-white
              rounded-lg
            "
          >
            <div class="flex items-center w-full mt-2">
              <span class="px-2 text-left"
                >Business Name <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <!-- <span class="w-1/6 px-2 text-left">Contact Name</span> -->
              <input
                type="text"
                name="businessName"
                @input="busNameVerification"
                :class="
                  validStatus.bussiness_name
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none'
                "
                placeholder="Business Name"
                required
                v-model="input.bussiness_name"
                @blur="onBlur('bussiness_name')"
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.bussiness_name"
              >
                {{ errors.bussiness_name }}
              </p>
            </div>
            <div class="flex items-center w-full mt-2">
              <span class="px-2 text-left"
                >Contact Name <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <!-- <span class="w-1/6 px-2 text-left">Contact Name</span> -->
              <input
                type="text"
                name="businessContactName"
                @input="contactNameVerification"
                :class="
                  validStatus.contact_name
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none'
                "
                placeholder="Contact Name"
                required
                v-model="input.contact_name"
                @blur="onBlur('contact_name')"
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.contact_name"
              >
                {{ errors.contact_name }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <span class="px-2 text-left"
                >ABN <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <input
                type="text"
                name="businessAbn"
                @input="abnVerification"
                :class="
                  validStatus.abn
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none'
                "
                placeholder="00 000 000 000"
                required
                v-model="input.abn"
                @blur="onBlur('abn')"
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.abn"
              >
                {{ errors.abn }}
              </p>
            </div>
            <div class="flex items-center w-full">
              <span class="px-2 text-left"
                >Phone Number <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full v-signup">
              <vue-tel-input
                @blur="onBlur('phn')"
                @input="onPhnInput"
                v-model="input.phone_number"
                mode="international"
              ></vue-tel-input>
              <!-- <input
                @input="phoneNumberVerification"
                type="tel"
                pattern="^[0-9]{10}$"
                minlength="10"
                maxlength="10"
                name="businessPhoneNumber"
                v-model="input.phone_number"
                :class="
                  validStatus.phone_number
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-1 leading-tight focus:outline-none'
                "
                placeholder="0491123456"
                required
                @blur="onBlur('phn')"
                v-on:keyup.enter="createAccount"
              /> -->
              <p
                class="text-xs italic text-left text-red-500 errorMsg mb-1"
                v-if="!validStatus.phone_number"
              >
                {{ errors.phone_number }}
              </p>
            </div>
          </div>

          <!-- <div
            class="flex flex-col items-center justify-start px-3 mx-4 mt-6 bg-white rounded-lg"
          >
            <div class="flex items-center w-full mt-2">
              <span class="px-2 text-left"
                >Card Owner Name <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <input
                type="text"
                name="cardName"
                @input="setFormData('cardData.name', $event.target.value)"
                :class="
                  validators.cardData.name.isValid
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none'
                "
                placeholder="Name"
                required
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg"
                v-if="!validators.cardData.name.isValid"
              >
                {{ validators.cardData.name.errorMsg }}
              </p>
            </div>
            <div class="flex items-center w-full mt-2">
              <span class="px-2 text-left"
                >Card Owner Address <span class="requiredField">*</span></span
              >
            </div>
            <div class="flex flex-col w-full">
              <input
                type="text"
                name="cardCountry"
                @input="setFormData('cardData.country', $event.target.value)"
                :class="
                  validators.cardData.country.isValid
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none'
                "
                placeholder="Country (2-character alphanumeric country code)"
                required
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg"
                v-if="!validators.cardData.country.isValid"
              >
                {{ validators.cardData.country.errorMsg }}
              </p>
            </div>
            <div class="flex flex-col w-full">
              <input
                type="text"
                name="cardState"
                @input="setFormData('cardData.state', $event.target.value)"
                :class="
                  validators.cardData.state.isValid
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none'
                "
                placeholder="State/Province"
                required
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg"
                v-if="!validators.cardData.state.isValid"
              >
                {{ validators.cardData.state.errorMsg }}
              </p>
            </div>
            <div class="flex flex-col w-full">
              <input
                type="text"
                name="cardCity"
                @input="setFormData('cardData.city', $event.target.value)"
                :class="
                  validators.cardData.city.isValid
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none'
                "
                class="flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0 "
                placeholder="City"
                required
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg"
                v-if="!validators.cardData.city.isValid"
              >
                {{ validators.cardData.city.errorMsg }}
              </p>
            </div>
            <div class="flex flex-col w-full">
              <input
                type="text"
                name="cardZip"
                @input="setFormData('cardData.zip', $event.target.value)"
                :class="
                  validators.cardData.zip.isValid
                    ? 'flex-1 block w-full mt-1 border-transparent rounded focus:border-white focus:ring-0'
                    : 'flex-1 block shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none'
                "
                placeholder="Postal/ZIP Code"
                required
              />
              <p
                class="text-xs italic text-left text-red-500 errorMsg"
                v-if="!validators.cardData.zip.isValid"
              >
                {{ validators.cardData.zip.errorMsg }}
              </p>
            </div>
            <div
              ref="card_element"
              id="card-element"
              class="w-full p-4 mx-1 my-2 bg-gray-200"
            />
          </div> -->
          <div class="flex px-4 mt-2 ml-2">
            <span class="text-left text-gray-500">
              You can add additional information about your business at any time
              from the Vendor Console tab.
            </span>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import Loading from "vue-loading-overlay";
// import axios from "axios";
// import { mapGetters, mapMutations } from "vuex";

export default defineComponent({
  setup() {
    const accountData = reactive({
      email: "",
      password: "",
      repassword: "",
      name: "",
      phone_number: null,
    });
    const businessData = reactive({
      business_name: "",
      abn: null,
    });
    const cardData = reactive({
      name: "",
      country: "",
      state: "",
      city: "",
      zip: "",
    });
    return {
      accountData,
      businessData,
      cardData,
    };
  },
  data() {
    return {
      phnData: {},
      blurStatus: {
        email: "",
        password: "",
        confirm_password: "",
        bussiness_name: "",
        contact_name: "",
        abn: "",
        phone_number: "",
      },
      input: {
        email: "",
        password: "",
        confirm_password: "",
        bussiness_name: "",
        contact_name: "",
        abn: "",
        phone_number: "",
        country_code: "",
      },
      errors: {
        email: "",
        password: "",
        confirm_password: "",
        bussiness_name: "",
        contact_name: "",
        abn: "",
        phone_number: "",
      },
      validStatus: {
        email: true,
        password: true,
        confirm_password: true,
        bussiness_name: true,
        contact_name: true,
        abn: true,
        phone_number: true,
      },
      stripe: {},
      isLoading: false,
      fullPage: true,
      loader: "bars",
      popupmodal: false,
      error: "",
      response: "",
      payment_method: "",
      style: {
        base: {
          color: "#000",
          fontWeight: 500,
          fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
          fontSize: "16px",
          fontSmoothing: "antialiased",
        },
        invalid: {
          color: "#FC011F",
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      },
    };
  },
  watch: {
    email(value) {
      return this.validateEmail(value);
    },
    password(value) {
      return this.validatePassword(value);
    },
    repassword(value) {
      return this.validateConfirmPassword(value);
    },
    businessname(value) {
      return this.validateBusinessname(value);
    },
    businessContactName(value) {
      return this.validateBusinesscontactName(value);
    },
    businessABN(value) {
      return this.validateBusinessABN(value);
    },
    businessPhoneNumber(value) {
      return this.validateBusinessphoneNumber(value);
    },
    cardName(value) {
      return this.validateCardName(value);
    },
    cardCountry(value) {
      return this.validateCardCountry(value);
    },
    cardState(value) {
      return this.validateCardState(value);
    },
    cardCity(value) {
      return this.validateCardCity(value);
    },
    cardZip(value) {
      return this.validateCardZip(value);
    },
  },
  methods: {
    setFormData(val, e) {
      let payload = {
        input: val,
        value: e,
      };
      this.$store.commit("auth/registration/setFormData", payload);
    },
    initStripe() {
      this.$nextTick(async () => {
        this.stripe = await loadStripe(process.env.VUE_APP_PUBLISHABLE_KEY);
        let elements = this.stripe.elements();
        this.card = elements.create("card", {
          hidePostalCode: true,
          style: this.style,
        });
        this.card.mount(this.$refs.card_element);
      });
    },
    createAccount() {
      let arr = [
        "email",
        "password",
        "confirm_password",
        "bussiness_name",
        "contact_name",
        "abn",
        "phn",
      ];
      for (let index = 0; index < arr.length; index++) {
        this.onBlur(arr[index]);
      }
      if (
        this.validStatus.email &&
        this.validStatus.password &&
        this.validStatus.confirm_password &&
        this.validStatus.bussiness_name &&
        this.validStatus.contact_name &&
        this.validStatus.abn &&
        this.validStatus.phone_number
      ) {
        this.isLoading = true;
        let reqData = {
          name: this.input.contact_name,
          email: this.input.email,
          phone_number: this.input.phone_number,
          password_confirmation: this.input.confirm_password,
          role: 2,
          business_name: this.input.bussiness_name,
          abn: this.input.abn.replace(/\s+/g, ""),
          password: this.input.password,
          country_code: this.input.country_code,
        };
        this.$http
          .post("auth/register", reqData)
          .then((response) => {
            this.response = response.data;
            sessionStorage.setItem("OTP_UserID", response.data.data.user.id);
            sessionStorage.setItem("acktoken", response.data.data.access_token)
            this.$router.push({ name: "OTPVerification" });
            this.isLoading = false;
            this.$swal.fire({
              icon: "success",
              title: response.data.message,
              timer: 3000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response.data);
            let errorMessage = "";
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (
                Array.isArray(error.response.data.message) &&
                error.response.data.message.length
              ) {
                errorMessage = error.response.data.message[0];
              } else if (typeof error.response.data.message === "string") {
                errorMessage = error.response.data.message;
              } else {
                errorMessage = "Something went wrong.";
              }
            } else {
              errorMessage = "Something went wrong.";
            }
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              title: errorMessage,
              timer: 5000,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
            });
          });
        console.log(reqData);
      } else {
        console.log("Invalid");
        console.log(this.validStatus);
      }
      return;
      if (
        this.formData.data.accountData.password ===
          this.formData.data.accountData.repassword &&
        this.formData.validators.accountData.password.isValid &&
        this.formData.validators.accountData.repassword.isValid
      ) {
        if (this.formData.validators.accountData.password.length < 6) {
          this.error = "The password must be at least 6 characters";
          return;
        }
        this.isLoading = true;
        this.error = "";
        let reqData = {
          name: this.formData.data.accountData.name,
          email: this.formData.data.accountData.email,
          phone_number: this.formData.data.accountData.phone_number,
          password_confirmation: this.formData.data.accountData.repassword,
          role: 2,
          business_name: this.input.business_name,
          abn: this.formData.data.businessData.abn,
          password: this.formData.data.accountData.password,
        };
        console.log(reqData);
        this.$http
          .post("auth/register", reqData)
          .then((response) => {
            this.response = response.data;
            this.$router.push({ name: "VendorSignin" });
            this.isLoading = false;
            this.$swal.fire({
              icon: "success",
              text: response.data.message,
              timer: 3000,
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.isLoading = false;
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong while Registering your Account!",
              timer: 5000,
            });
          });
        // let additionalData = {
        //   name: this.formData.data.cardData.name,
        //   address_country: this.formData.data.cardData.country,
        //   address_state: this.formData.data.cardData.state,
        //   address_city: this.formData.data.cardData.city,
        //   address_zip: this.formData.data.cardData.zip
        // };
        // this.stripe.createToken(this.card, additionalData).then(result => {
        //   if (result.error) {
        //     this.$swal.fire({
        //       icon: "error",
        //       title: "Card Error",
        //       text: result.error.message,
        //       timer: 5000
        //     });
        //     console.log("createToken errrror");
        //   } else {
        //     this.createPaymentMethod(result.token);
        //   }
        // });
      }
    },
    createPaymentMethod(token) {
      let that = this;
      let address = {
        city: this.formData.data.cardData.city,
        country: this.formData.data.cardData.country,
        postal_code: this.formData.data.cardData.zip,
        state: this.formData.data.cardData.state,
      };
      this.stripe
        .createPaymentMethod({
          type: token.type,
          card: this.card,
          billing_details: {
            name: this.formData.data.cardData.name,
            address: address,
          },
        })
        .then(function (result) {
          // Handle result.error or result.paymentMethod
          if (result.error) {
            // Inform the user if there was an error.
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong while Registering your Card!",
              timer: 5000,
            });
            console.log("createPaymentMethod error", result.error);
          } else {
            // Send the token to server.
            that.createVendorAccount(token, result.paymentMethod);
          }
        });
    },
    createVendorAccount(token, paymentMethod) {
      let body = {
        stripe: {
          token_id: token.id,
          paymentmethod_id: paymentMethod.id,
        },
        account: { ...this.formData.data.accountData, role: "vendor" },
        business: this.formData.data.businessData,
      };
      this.$http
        .post("user/signup", body)
        .then((response) => {
          this.response = response.data;
          this.$router.push({ name: "VendorSignin" });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong while Registering your Account!",
            timer: 5000,
          });
        });
    },
    validateEmail(value) {
      this.$store.commit("auth/registration/setEmailValidator", value);
    },
    validatePassword(value) {
      this.$store.commit("auth/registration/setPasswordValidator", value);
    },
    validateConfirmPassword(value) {
      this.$store.commit(
        "auth/registration/setConfirmPasswordValidator",
        value
      );
    },
    validateBusinessname(value) {
      this.$store.commit(
        "auth/registration/setBusinessDataNameValidator",
        value
      );
    },
    validateBusinesscontactName(value) {
      this.$store.commit(
        "auth/registration/setBusinessDataContactNameValidator",
        value
      );
    },
    validateBusinessABN(value) {
      this.$store.commit(
        "auth/registration/setBusinessDataABNValidator",
        value
      );
    },
    validateBusinessphoneNumber(value) {
      this.$store.commit(
        "auth/registration/setBusinessDataPhoneNumberValidator",
        value
      );
    },
    validateCardName(value) {
      this.$store.commit("auth/registration/setCardDataName", value);
    },
    validateCardCountry(value) {
      this.$store.commit("auth/registration/setCardDataCountry", value);
    },
    validateCardState(value) {
      this.$store.commit("auth/registration/setCardDataState", value);
    },
    validateCardCity(value) {
      this.$store.commit("auth/registration/setCardDataCity", value);
    },
    validateCardZip(value) {
      this.$store.commit("auth/registration/setCardDataZip", value);
    },
    validateEmpty(value) {
      return value ? true : false;
    },
    emailVerification() {
      if (this.blurStatus.email) {
        if (this.input.email) {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (re.test(this.input.email)) {
            this.errors.email = "";
            this.validStatus.email = true;
          } else {
            this.errors.email = "Please enter a valid email";
            this.validStatus.email = false;
          }
        } else {
          this.errors.email = "Email is required";
          this.validStatus.email = false;
        }
      } else {
        this.errors.email = "";
        this.validStatus.email = true;
      }
    },
    passwordVerification() {
      if (this.blurStatus.password) {
        if (this.input.password) {
          if (this.input.password.length < 6) {
            this.errors.password = "The password must be at least 6 characters";
            this.validStatus.password = false;
          } else {
            this.errors.password = "";
            this.validStatus.password = true;
          }
        } else {
          this.errors.password = "Password is required";
          this.validStatus.password = false;
        }
      } else {
        this.errors.password = "";
        this.validStatus.password = true;
      }
    },
    cpasswordVerification() {
      if (this.blurStatus.confirm_password) {
        if (this.input.confirm_password) {
          if (
            this.input.password &&
            this.input.password != this.input.confirm_password
          ) {
            this.errors.confirm_password =
              "Password and confirm password does not match";
            this.validStatus.confirm_password = false;
          } else {
            this.errors.confirm_password = "";
            this.validStatus.confirm_password = true;
          }
        } else {
          this.errors.confirm_password = "Confirm password is required";
          this.validStatus.confirm_password = false;
        }
      } else {
        this.errors.confirm_password = "";
        this.validStatus.confirm_password = true;
      }
    },
    busNameVerification() {
      if (this.blurStatus.bussiness_name) {
        if (this.input.bussiness_name) {
          if (
            this.input.bussiness_name.length < 2 ||
            this.input.bussiness_name.length > 100
          ) {
            this.errors.bussiness_name =
              "Business name should be greater than 2 and less than 100 characters";
            this.validStatus.bussiness_name = false;
          } else {
            this.errors.bussiness_name = "";
            this.validStatus.bussiness_name = true;
          }
        } else {
          this.errors.bussiness_name = "Bussiness name is required";
          this.validStatus.bussiness_name = false;
        }
      } else {
        this.errors.bussiness_name = "";
        this.validStatus.bussiness_name = true;
      }
    },
    contactNameVerification() {
      if (this.blurStatus.contact_name) {
        if (this.input.contact_name) {
          this.errors.contact_name = "";
          this.validStatus.contact_name = true;
        } else {
          this.errors.contact_name = "Contact name is required";
          this.validStatus.contact_name = false;
        }
      } else {
        this.errors.contact_name = "";
        this.validStatus.contact_name = true;
      }
    },
    validateABN(abn) {
      var isValid = true;
      abn = abn.toString();
      //remove all spaces
      abn = abn.replace(/\s/g, "");
      //0. ABN must be 11 digits long
      isValid &= abn && /^\d{11}$/.test(abn);
      if (isValid) {
        var weightedSum = 0;
        var weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
        //Rules: 1,2,3
        for (var i = 0; i < weight.length; i++) {
          weightedSum += (parseInt(abn[i]) - (i === 0 ? 1 : 0)) * weight[i];
        }
        //Rules: 4,5
        isValid &= weightedSum % 89 === 0;
      }
      return isValid;
    },
    abnVerification() {
      if (this.blurStatus.abn) {
        if (this.input.abn) {
          let valid = this.validateABN(this.input.abn);
          if (valid) {
            this.errors.abn = "";
            this.validStatus.abn = true;
          } else {
            this.errors.abn =
              "Invalid ABN (only numbers with maximum length 11)";
            this.validStatus.abn = false;
          }
        } else {
          this.errors.abn = "ABN is required";
          this.validStatus.abn = false;
        }
      } else {
        this.errors.abn = "";
        this.validStatus.abn = true;
      }
    },
    phoneNumberVerification() {
      if (this.blurStatus.phone_number) {
        if (this.input.phone_number) {
          const rgex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          if (
            rgex.test(this.input.phone_number) &&
            this.input.phone_number.length > 9 &&
            this.input.phone_number.length < 15
          ) {
            this.errors.phone_number = "";
            this.validStatus.phone_number = true;
          } else {
            this.errors.phone_number = "Invalid phone number";
            this.validStatus.phone_number = false;
          }
        } else {
          this.errors.phone_number = "Phone number is required";
          this.validStatus.phone_number = false;
        }
      } else {
        this.errors.phone_number = "";
        this.validStatus.phone_number = true;
      }
    },
    onPhnInput(value, data) {
      if (data && data.valid) {
        this.phnData = data;
        if (this.blurStatus.phone_number) {
          this.errors.phone_number = "";
          this.validStatus.phone_number = true;
          this.input.phone_number = data.nationalNumber;
          this.input.country_code = data.country
            ? data.country.dialCode
              ? data.country.dialCode
              : ""
            : "";
        }
      } else if (data && !data.valid) {
        this.phnData = data;
        if (this.blurStatus.phone_number) {
          this.errors.phone_number = "Invalid phone number";
          this.validStatus.phone_number = false;
          this.input.phone_number = data.nationalNumber;
          this.input.country_code = data.country
            ? data.country.dialCode
              ? data.country.dialCode
              : ""
            : "";
        }
      }
      if (this.blurStatus.phone_number && !this.input.phone_number) {
        this.errors.phone_number = "Phone number is required";
        this.validStatus.phone_number = false;
      }
    },
    onBlur(field) {
      switch (field) {
        case "email":
          this.blurStatus.email = true;
          this.emailVerification();
          break;
        case "password":
          this.blurStatus.password = true;
          this.passwordVerification();
          break;
        case "confirm_password":
          this.blurStatus.confirm_password = true;
          this.cpasswordVerification();
          break;
        case "bussiness_name":
          this.blurStatus.bussiness_name = true;
          this.busNameVerification();
          break;
        case "contact_name":
          this.blurStatus.contact_name = true;
          this.contactNameVerification();
          break;
        case "abn":
          this.blurStatus.abn = true;
          this.abnVerification();
          break;
        case "phn":
          this.blurStatus.phone_number = true;
          // this.phoneNumberVerification();
          this.onPhnInput("", this.phnData);
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.popupmodal = true;
    // this.initStripe();
  },
  computed: {
    // ...mapGetters({
    //   accountData: "auth/registration/formAccountData",
    //   businessData: "auth/registration/formBusinessData",
    //   cardData: "auth/registration/formCardData"
    // }),
    // ...mapMutations({
    //   accountData: "auth/registration/setFormAccountData",
    //   businessData: "auth/registration/setFormBusinessData",
    //   cardData: "auth/registration/setFormCardData"
    // }),
    formData() {
      return this.$store.getters["auth/registration/formData"];
    },
    validators() {
      return this.$store.getters["auth/registration/validators"];
    },
    email() {
      return this.formData.data.accountData.email;
    },
    password() {
      return this.formData.data.accountData.password;
    },
    repassword() {
      return this.formData.data.accountData.repassword;
    },
    businessname() {
      return this.formData.data.businessData.business_name;
    },
    businessContactName() {
      return this.formData.data.accountData.name;
    },
    businessABN() {
      return this.formData.data.businessData.abn;
    },
    businessPhoneNumber() {
      return this.formData.data.accountData.phone_number;
    },
    cardName() {
      return this.formData.data.cardData.name;
    },
    cardCountry() {
      return this.formData.data.cardData.country;
    },
    cardState() {
      return this.formData.data.cardData.state;
    },
    cardCity() {
      return this.formData.data.cardData.city;
    },
    cardZip() {
      return this.formData.data.cardData.zip;
    },
  },
  components: {
    Loading,
  },
});
</script>
<style  lang="scss">
.errorMsg {
  margin-left: 10px;
}

.requiredField {
  color: red;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
}
.vue-tel-input {
  border: none;
}
.vue-tel-input:focus-within {
  box-shadow: none;
}
.v-signup {
  .vti__input:focus{
   outline: none;
    outline-offset: 0px;
    --tw-ring-inset: 0;
    --tw-ring-offset-width: 0px;
    --tw-ring-color: transparent;
    --tw-ring-offset-shadow: none;
    --tw-ring-shadow: none;
    box-shadow: none;
    border-color: none;
  }
 :focus-visible {
   outline: none;
 }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input::placeholder {
  color: #2c3e508c;
}
</style>
